import React, {useEffect, useRef, useState} from 'react';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../carouselCSS.css';
import {IconButton} from '@mui/material';
import {North, South} from "@mui/icons-material";
import carouselData from '../data/carouselData';

const ImageCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollTimeoutRef = useRef(null);

    const handleUpClick = () => {
        setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : carouselData.length - 1));
    };

    const handleDownClick = () => {
        setActiveIndex((prevIndex) => (prevIndex < carouselData.length - 1 ? prevIndex + 1 : 0));
    };

    const handleScroll = (event) => {
        if (scrollTimeoutRef.current) {
            return;
        }

        if (event.deltaY < 0) {
            handleUpClick();
        } else {
            handleDownClick();
        }

        scrollTimeoutRef.current = setTimeout(() => {
            scrollTimeoutRef.current = null;
        }, 1500);
    };

    useEffect(() => {
        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);

    return (
        <div className="custom-arrows">
            <div className="carousel-container">
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    showArrows={false}
                    emulateTouch={true}
                    axis="vertical"
                    selectedItem={activeIndex}
                >
                    {carouselData.map((item, index) => (
                        <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
                            <img src={item.imageURL} alt={`Image ${index}`} className="background-image"/>
                            <div className="text-container">
                                <img src={'./img/final_logo_cut.png'} alt='logo' style={{width: '10%'}}
                                     className='floating_heartbeat'/>
                                <h1 style={{marginTop: '5px'}}>{item.headline}</h1>
                                {item.text && <h2>{item.text}</h2>}
                                <a href='https://lukso.network/' target='_blank'>
                                    <img src={'./img/LUKSO.svg'} alt='logo' style={{width: '10%', marginTop: '-5px'}}/>
                                </a>
                                <div style={{marginTop: '100px'}}>
                                    <h3>created by</h3>
                                    <div className='image-row'>
                                        <a href='https://x.com/dariaonchain' target='_blank'>
                                            <div className="image-container">
                                                <img src="/img/daria_cut.jpg" alt="Daria"/>
                                                <div className="overlay">test</div>
                                            </div>
                                        </a>
                                        <a href='https://www.linkedin.com/in/stefan-gradl/' target='_blank'>
                                            <div className="image-container">
                                                <img src="/img/stefan_cut.jpg" alt="Stefan"/>
                                                <div className="overlay">test</div>
                                            </div>
                                        </a>
                                        <a href='https://x.com/lyx_oracle' target='_blank'>
                                            <div className="image-container">
                                                <img src="/img/markus_cut.jpg" alt="Markus"/>
                                                <div className="overlay">test</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>

                {activeIndex > 0 && (
                    <div className="arrow-button top-arrow">
                        <IconButton
                            aria-label="up"
                            onClick={handleUpClick}
                            style={{transform: 'translateX(20px)', transition: 'transform 10s ease'}}
                        >
                            <North sx={{color: 'white'}} fontSize="large"/>
                        </IconButton>
                    </div>
                )}
                {activeIndex < carouselData.length - 1 && (
                    <div className="arrow-button bottom-arrow">
                        <IconButton
                            aria-label="down"
                            onClick={handleDownClick}
                            style={{transform: 'translateY(-25px)', transition: 'transform 0.5s ease'}}
                        >
                            <South sx={{color: 'white'}} fontSize="large"/>
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageCarousel;
