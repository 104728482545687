const carouselData = [
    {
        imageURL: process.env.PUBLIC_URL + '/img/bg_SE.svg',
        headline: 'SOULECHO',
        text: 'A Phygital Immersive Experience on'
    }
];

export default carouselData;


/*
{imageURL: process.env.PUBLIC_URL + '/img/image2.jpg', headline: 'SOON'},
{imageURL: process.env.PUBLIC_URL + '/img/image2.jpg', headline: 'THERE WILL BE'},
{imageURL: process.env.PUBLIC_URL + '/img/image4.jpg', headline: 'MORE'},
*/
