import React from 'react';
import './App.css';
import ImageCarousel from './components/ImageCarousel'; // Adjust the path to your ImageCarousel component

const App = () => {
    return (
        <div className="App">
            <ImageCarousel/>
        </div>
    );
};

export default App;
